export const linksList = {
  en: [
    { title: 'about', url: '/about/' },
    { title: 'projects', url: '/projects/' },
    { title: 'certificates', url: '/certificates/' },
    { title: 'services', url: '/services/' },
    { title: 'contact', url: '/contact/' },
    { title: 'financing', url: '/financing/' },
  ],
  lt: [
    { title: 'about', url: '/apie-mus/', name: 'apie mus' },
    { title: 'projects', url: '/projektai/', name: 'projektai' },
    { title: 'certificates', url: '/atestatai/', name: 'atestatai' },
    { title: 'services', url: '/paslaugos/', name: 'paslaugos' },
    { title: 'contact', url: '/kontaktai/', name: 'kontaktai' },
    { title: 'financing', url: '/finansavimas/', name: 'finansavimas' },
  ],
};

export const availableLanguages = {
  en: 'en_US',
  lt: 'lt_LT',
};

export const getLink = (page, lang) => {
  return linksList[lang].find(p => p.title === page);
};

export const getServiceLink = language => {
  const isLithuanian = language === 'lt';
  const prefix = isLithuanian ? '/lt' : '';

  return `${prefix}${getLink('services', language)?.url}#${
    isLithuanian ? 'paslauga' : 'service'
  }`;
};
