export const colors = {
  white: '#fefefe',
  cloud: '#eeeef2',
  red: '#fd4341',
  green: '#50e3c2',

  gray100: '#f5f5f5',
  gray200: '#eeeeee',
  gray300: '#e0e0e0',
  gray400: '#bdbdbd',
  gray500: '#9e9e9e',
  gray600: '#757575',
  gray700: '#616161',
  gray800: '#424244',
  gray900: '#212121',

  blue100: '#dde7fd',
  blue200: '#b3cbff',
  blue300: '#799eec',
  blue400: '#5289ff',
  blue500: '#1b62f8',
  blue600: '#074cdf',
  blue700: '#053bad',
  blue800: '#0f378a',
  blue900: '#001780',
};
