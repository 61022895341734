import { ReactComponent as ArrowExtended } from '~/static/svg/arrow-extended.svg';
import { ReactComponent as BagIcon } from '~/static/svg/bag.svg';
import { ReactComponent as BoltIcon } from '~/static/svg/bolt.svg';
import { ReactComponent as ChatIcon } from '~/static/svg/chat.svg';
import { ReactComponent as CloseIcon } from '~/static/svg/close.svg';
import { ReactComponent as EnvelopeIcon } from '~/static/svg/envelope.svg';
import { ReactComponent as FacebookIcon } from '~/static/svg/facebook.svg';
import { ReactComponent as ImageIcon } from '~/static/svg/image.svg';
import { ReactComponent as InfoIcon } from '~/static/svg/info.svg';
import { ReactComponent as LinkedinIcon } from '~/static/svg/linkedin.svg';
import { ReactComponent as LocationIcon } from '~/static/svg/location.svg';
import { ReactComponent as Logomark } from '~/static/svg/logomark.svg';
import { ReactComponent as FullLogo } from '~/static/svg/nrgy-full-logo.svg';
import { ReactComponent as LogoIcon } from '~/static/svg/nrgy-logo.svg';
import { ReactComponent as PersonIcon } from '~/static/svg/person.svg';
import { ReactComponent as PeopleIcon } from '~/static/svg/persons.svg';
import { ReactComponent as PhoneIcon } from '~/static/svg/phone.svg';
import { ReactComponent as SendIcon } from '~/static/svg/send.svg';
import { ReactComponent as ToolIcon } from '~/static/svg/tool.svg';

export const Icons = {
  facebook: FacebookIcon,
  bag: BagIcon,
  bolt: BoltIcon,
  chat: ChatIcon,
  close: CloseIcon,
  envelope: EnvelopeIcon,
  info: InfoIcon,
  linkedin: LinkedinIcon,
  location: LocationIcon,
  logomark: Logomark,
  person: PersonIcon,
  persons: PeopleIcon,
  send: SendIcon,
  tool: ToolIcon,
  phone: PhoneIcon,
  fullLogo: FullLogo,
  image: ImageIcon,
  logo: LogoIcon,
  'arrow-extended': ArrowExtended,
};
