import React from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon';
import { colors } from '~/theme';

const Wrapper = styled.a`
  display: flex;
  color: ${colors.gray600};
  text-decoration: none;
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
  transition: color 0.25s ease-in-out;

  svg {
    margin-right: ${rem(8)};
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    color: ${colors.blue500};

    svg {
      transform: rotate(15deg) scale(1.1);
    }
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      span {
        color: ${colors.gray400};
        font-size: ${rem(14)};
      }
    `}
`;

export const IconLink = ({
  size = 'lg',
  iconSize,
  iconName,
  title,
  href,
  className,
  isSmall,
}) => {
  return (
    <Wrapper href={href} size={size} className={className} isSmall={isSmall}>
      <Icon size={iconSize} name={iconName} />
      <span>{title}</span>
    </Wrapper>
  );
};
