import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { borderRadius, colors, mediaQuery, zIndexes } from '~/theme';

export const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    
    &--after-open {
      opacity: 1;
    }
    
    &--before-close {
      opacity: 0;
    }
  }

  .modal {
    display: flex;
    position: relative;
    justify-content: center;
    padding: ${rem(48)};
    background-color: ${colors.gray100};
    border-radius: ${borderRadius.xl};
    max-height: 90vh;
    overflow-y: auto;

    ${mediaQuery('sm')} {
      width: 100%;
      height: 100%;
      border-radius: 0;
      max-height: unset;
        padding: ${rem(24)};
    }

    &__overlay {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: ${zIndexes.modal};
    }
  }
`;
