import React from 'react';
import { Link } from 'gatsby';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Arrow } from './arrow';

import { ConditionalWrap } from '~/components/animations/conditional-wrap';
import { isObjectEmpty } from '~/core/helpers';
import { colors } from '~/theme';

const PADDING_VALUES = {
  sm: `${rem(8)}  ${rem(24)}`,
  md: `${rem(12)}  ${rem(36)}`,
  lg: `${rem(16)}  ${rem(48)}`,
  xl: `${rem(16)}  ${rem(72)}`,
};

const FONT_SIZE_VALUES = {
  sm: rem(12),
  md: rem(14),
  lg: rem(14),
  xl: rem(16),
};

const ButtonWrapper = styled.button`
  padding: ${({ size }) => PADDING_VALUES[size]};
  line-height: 1;
  border-radius: ${rem(4)};
  text-decoration: none;
  background: transparent;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border 0.25s ease-in-out;
  display: inline-flex;
  font-size: 0;
  line-height: 1;
  text-decoration: none;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  span {
    display: flex;
    font-size: ${({ size }) => FONT_SIZE_VALUES[size]};
    position: relative;
    transition: transform 0.25s ease-out;

    svg {
      height: ${({ size }) => (size === 'lg' ? rem(16) : rem(12))};
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.25s ease-out;
      will-change: right, opacity;
    }
  }

  &:hover {
    span {
      transform: translateX(${rem(-8)});

      svg {
        opacity: 1;
        right: ${rem(-24)};
      }
    }
  }

  ${({ variation, isDark }) =>
    variation === 'fill' &&
    css`
      background-color: ${colors.blue500};
      color: ${colors.gray100};

      &:hover {
        background-color: transparent;
        border-color: ${isDark ? colors.gray100 : colors.blue500};
        color: ${isDark ? colors.gray100 : colors.blue500};
      }
    `}

  ${({ variation }) =>
    variation === 'outline' &&
    css`
      border: 1px solid ${colors.blue500};
      color: ${colors.blue500};

      &:hover {
        background-color: ${colors.blue500};
        color: ${colors.gray100};
      }
    `}
  
    ${({ variation }) =>
    (variation === 'ghost' || variation === 'withArrow') &&
    css`
      background-color: transparent;
      color: ${colors.blue500};
      margin-left: ${rem(-8)};
      margin-top: ${rem(-16)};
      padding-left: ${rem(8)};
      padding-right: ${rem(8)};
    `}
`;

// size: sm, md, lg, xl
// variation: fill, outline, ghost, withArrow
export const Button = ({
  children,
  size,
  variation,
  link,
  type = 'button',
  className,
  isDark,
  onClick,
  isDisabled,
  ...animationProps
}) => (
  <ConditionalWrap
    condition={!isObjectEmpty(animationProps)}
    {...animationProps}
  >
    <ButtonWrapper
      className={className}
      onClick={onClick}
      type={link ? null : type}
      size={size}
      variation={variation}
      as={link && Link}
      to={link}
      isDark={isDark}
      disabled={isDisabled}
    >
      <span>
        {children}
        <Arrow />
      </span>
    </ButtonWrapper>
  </ConditionalWrap>
);
