module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layout/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","lt"],"defaultLanguage":"en","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":["lt"]},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true,"excludeLanguages":["lt"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.nrgy.lt/graphql","type":{"UserRole":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"User":{"exclude":true},"Tag":{"exclude":true},"Taxonomy":{"exclude":true},"Menu":{"exclude":true},"Category":{"exclude":true},"Comment":{"exclude":true},"Post":{"exclude":true},"PostFormat":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NRGY","short_name":"nrgy","start_url":"/","background_color":"#1B62F8","theme_color":"#1B62F8","display":"minimal-ui","icon":"src/static/images/nrgy-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29a1337db78737cf455df72ae3ce5629"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
