import React from 'react';

export const Arrow = () => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 7L17 7" stroke="currentColor" strokeLinecap="square" />
    <path d="M12 1L18 7L12 13" stroke="currentColor" strokeLinecap="square" />
  </svg>
);
