import React from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon';
import { borderRadius, boxShadow900, colors, mediaQuery } from '~/theme';

const InputContainer = styled.div`
  padding: ${rem(16 + 1)};
  line-height: 1;
  display: flex;
  align-items: center;
  border-radius: ${borderRadius.md};
  background-color: ${colors.white};
  box-shadow: ${boxShadow900};
  box-sizing: border-box;
  border: 1px solid transparent;

  svg {
    min-width: 24px;
  }

  ${({ isError }) =>
    isError &&
    css`
      color: ${colors.red};
      border: 1px solid red;
    `}

  ${({ isTextarea }) =>
    isTextarea &&
    css`
      align-items: flex-start;
      height: auto;
    `}

  ${mediaQuery('sm')} {
    padding: ${rem(14)};

    ::placeholder {
      font-size: ${rem(14)};
    }

    svg {
      min-width: 20px;
    }
  }
`;

const InputStyled = styled.input`
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
  margin-left: ${rem(16)};
  line-height: 1;
  color: ${colors.gray900};
  width: 100%;

  &::placeholder {
    color: ${colors.gray500};
  }
`;

export const Input = ({
  iconName,
  placeholder,
  type = 'text',
  className,
  name,
  register,
  errors,
  pattern,
}) => {
  const isError = Boolean(errors[name]);
  const isTextarea = type === 'textarea';

  const textareaProps = isTextarea ? { as: 'textarea', rows: 4 } : {};

  return (
    <InputContainer
      className={className}
      isError={isError}
      isTextarea={isTextarea}
    >
      <Icon name={iconName} size="24" />
      <InputStyled
        {...register(name, { required: true, pattern })}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        className="input-field"
        {...textareaProps}
      />
    </InputContainer>
  );
};
