import React from 'react';

import { Icons } from '~/components/icon/icons';

export const Icon = ({
  size = 16,
  name,
  className,
  fullHeight,
  disableInlineStyles,
}) => {
  const IconComponent = Icons[name];

  if (!IconComponent) return null;

  return (
    <IconComponent
      style={
        disableInlineStyles ? {} : { height: size, width: !fullHeight && size }
      }
      className={className}
    />
  );
};
