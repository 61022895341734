import React from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { ConditionalWrap } from '~/components/animations/conditional-wrap';
import { isObjectEmpty } from '~/core/helpers';
import { colors, mediaQuery } from '~/theme';

const HEADING_SCALE_RATIO = 1.25;
const HEADING_BASE_SIZE = 20;

const getHeadingSize = headingLevel => {
  let size = HEADING_BASE_SIZE;
  Array.apply(null, Array(headingLevel - 1)).map(
    _ => (size = size * HEADING_SCALE_RATIO)
  );

  return css`
    font-size: ${rem(size)};

    ${mediaQuery('xl')} {
      font-size: ${rem(size / HEADING_SCALE_RATIO)};
    }

    ${mediaQuery('sm')} {
      font-size: ${headingLevel === 6 && rem(36)};
    }
  `;
};

const Text = styled.h1`
  font-family: 'syne', sans-serif;
  color: ${({ color }) => color};
  margin: 0 0 ${({ spacing }) => rem(SPACING_VALUES[spacing])};
  line-height: 1.1;
  letter-spacing: normal;
  font-weight: 700;
  text-transform: none;

  ${({ size }) => size === 'h1' && getHeadingSize(6)}

  ${({ size }) => size === 'h2' && getHeadingSize(5)}

    ${({ size }) => size === 'h3' && getHeadingSize(4)}

    ${({ size }) => size === 'h4' && getHeadingSize(3)}

    ${({ size }) => size === 'h5' && getHeadingSize(2)}

    ${({ size }) => size === 'h6' && getHeadingSize(1)}
`;

const SPACING_VALUES = {
  sm: 8,
  md: 12,
  lg: 16,
  xl: 32,
};

// tag: h1, h2, h3, h4, h5, h6
// size: h1, h2, h3, h4, h5, h6
// spacing: sm, md, lg, xl
export const Heading = ({
  children,
  tag,
  size,
  color = colors.gray800,
  spacing = 'md',
  className,
  ...animationProps
}) => {
  return (
    <ConditionalWrap
      condition={!isObjectEmpty(animationProps)}
      {...animationProps}
    >
      <Text
        as={tag}
        size={size}
        color={color}
        spacing={spacing}
        className={className}
      >
        {children}
      </Text>
    </ConditionalWrap>
  );
};
