import React from 'react';
import Div100vh from 'react-div-100vh';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { AnimatedLogo } from '../animated-logo';

import { colors, zIndexes } from '~/theme';

const containerVariants = {
  visible: {
    transition: {
      staggerChildren: 0.5,
    },
  },
  exit: {
    transition: {
      staggerDirection: -1,
    },
  },
};

const getTileVariants = (index, initialAnimation) => ({
  hidden: { width: 0 },
  visible: {
    width: '100%',
    transition: {
      duration: 0.5,
      delay: index * 0.07,
    },
  },
  exit: index => ({
    width: `0`,
    transition: {
      duration: 0.25,
      delay: index * 0.07 + (initialAnimation ? 1 : 0),
    },
  }),
});

export const Transition = ({
  isAnimating,
  initialAnimation,
  onAnimationComplete,
}) => {
  return (
    <AnimatePresence>
      {isAnimating && (
        <Div100vh style={{ minHeight: '100rvh' }}>
          <LoaderContainer
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            isAnimating={isAnimating}
            onAnimationComplete={definition =>
              definition === 'exit' && onAnimationComplete()
            }
          >
            {[...Array(5).keys()].map(index => (
              <LoaderTile
                key={index}
                custom={index}
                variants={getTileVariants(index, initialAnimation)}
                style={{ top: `${index * 20}%` }}
              />
            ))}

            {initialAnimation && <AnimatedLogo isAnimating={isAnimating} />}
          </LoaderContainer>
        </Div100vh>
      )}
    </AnimatePresence>
  );
};

const LoaderContainer = styled(motion.div)`
  position: fixed;
  z-index: ${zIndexes.loader};
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isAnimating }) =>
    !isAnimating &&
    css`
      pointer-events: none;
    `}
`;

const LoaderTile = styled(motion.div)`
  height: 20%;
  left: 0;
  position: absolute;
  background: ${colors.gray900};
`;
