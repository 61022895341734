import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { mediaQuery } from '~/theme/breakpoints';
import { colors } from '~/theme/colors';

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${rem(1300)};
  width: 100%;
  padding: 0 ${rem(16)};

  ${mediaQuery('xl')} {
    padding: 0 ${rem(40)};
  }

  ${mediaQuery('md')} {
    padding: 0 ${rem(32)};
  }

  ${mediaQuery('sm')} {
    padding: 0 ${rem(20)};
  }
`;

export const imageOverlay = animatePosition => css`
  transition: transform 0.25s ease-out;
  position: relative;

  > :first-child {
    transition: transform 0.25s ease-in-out;
  }

  &::after {
    background: ${colors.blue500};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
    transform: ${animatePosition && `translateY(${rem(-24)})`};

    > :first-child {
      transform: ${!animatePosition && `scale(1.1)`};
    }

    > :last-child {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(0);
    }

    &::after {
      opacity: 0.7;
    }
  }
`;

export const overlayIcon = css`
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5) rotate(-25deg);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  z-index: 2;
  color: ${colors.gray100};
`;
