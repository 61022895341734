import React, { useMemo } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import styled from 'styled-components';

import { Heading } from '~/components/heading';
import { Icon } from '~/components/icon';
import { IconBox } from '~/components/icon-box';
import { IconLink } from '~/components/icon-link';
import { Link } from '~/components/link';
import { Paragraph } from '~/components/paragraph';
import { getMappedOptions } from '~/core/data-mappers';
import { availableLanguages, getLink } from '~/core/links';
import { colors, Container, mediaQuery } from '~/theme';

export const Footer = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const {
    allWpService: { services },
    wp,
  } = useStaticQuery(graphql`
    query footerQuery {
      wp {
        ...OptionsFragment
      }
      allWpService {
        services: nodes {
          title
          locale {
            locale
          }
        }
      }
    }
  `);

  const options = getMappedOptions(wp);

  const serviceLinks = services.filter(
    ({ locale: { locale } }) => locale === availableLanguages[language]
  );

  const pageLinks = useMemo(
    () => [
      getLink('about', language),
      getLink('projects', language),
      getLink('certificates', language),
      getLink('services', language),
      getLink('contact', language),
    ],
    [language]
  );

  const renderHeading = title => (
    <FooterHeader tag="h3" size="6" color={colors.gray200}>
      {title}
    </FooterHeader>
  );

  const renderLinks = (links, url) => {
    return (
      <FooterLinks>
        {links.map((link, index) => (
          <li key={link.title}>
            <Link
              isArchor={url}
              href={url ? `${url}-${index + 1}` : link.url}
              title={link.name ?? link.title}
            />
          </li>
        ))}
      </FooterLinks>
    );
  };

  const getServiceLink = () => {
    const isLithuanian = language === 'lt';
    const prefix = isLithuanian ? '/lt' : '';

    return `${prefix}${getLink('services', language)?.url}#${
      isLithuanian ? 'paslauga' : 'service'
    }`;
  };

  return (
    <FooterWrapper>
      <FooterWatermark>
        <Icon size="100%" name="logomark" fullHeight />
      </FooterWatermark>
      <FooterContainer>
        <FooterTop>
          <FooterInfo lg={3} md={6} xs={12}>
            <FooterLogo name="logo" size={48} fullHeight />
            {options?.description && (
              <Paragraph size="sm" color={colors.gray400}>
                {options?.description}
              </Paragraph>
            )}
            <FooterSocials>
              <IconBox href={options?.facebook} iconName="facebook" isSmall />
            </FooterSocials>
          </FooterInfo>
          <Col lg={3} md={6} xs={12}>
            {renderHeading(t('footer.links'))}
            {renderLinks(pageLinks)}
          </Col>
          <Col lg={3} md={6} xs={12}>
            {renderHeading(t('footer.services'))}
            {renderLinks(serviceLinks, getServiceLink())}
          </Col>
          <Col lg={3} md={6} xs={12}>
            {renderHeading(t('footer.contact'))}
            <FooterContactLinks
              isSmall
              iconSize={20}
              iconName="envelope"
              title={options?.email}
              href={`mailto:${options?.email}`}
            />
            <FooterContactLinks
              isSmall
              iconSize={20}
              iconName="phone"
              title={`+${options?.phone}`}
              href={`tel:+${options?.phone}`}
            />
            <FooterContactLinks
              isSmall
              iconSize={20}
              iconName="location"
              title={`${options?.location}`}
              href="https://goo.gl/maps/WaHaZDDvUyEuzEjT8"
            />
          </Col>
        </FooterTop>
        <FooterBottom>
          <Col>
            © {new Date().getFullYear()} Nrgy LT. {t('footer.copyright')}
          </Col>
        </FooterBottom>
      </FooterContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: ${colors.gray900};
  padding-top: ${rem(96)};
  position: relative;
  overflow: hidden;
  margin-top: ${rem(128)};

  ${mediaQuery('md')} {
    padding-top: ${rem(48)};
    margin-top: ${rem(64)};
  }
`;

const FooterInfo = styled(Col)`
  > *:not(:last-child) {
    margin-bottom: ${rem(12)};
  }

  p {
    max-width: 30ch;
  }
`;

const FooterLogo = styled(Icon)`
  color: ${colors.gray100};
`;

const FooterSocials = styled.div`
  display: flex;
  left: -8px;
  position: relative;
  gap: ${rem(8)};
`;

const FooterContainer = styled(Container)`
  z-index: 1;
  position: relative;
`;

const FooterWatermark = styled.div`
  position: absolute;
  right: -10%;
  top: 0;
  bottom: 0;
  opacity: 0.5;

  ${mediaQuery('md')} {
    display: none;
  }
`;

const FooterTop = styled(Row)`
  padding-bottom: ${rem(96)};

  ${mediaQuery('lg')} {
    padding-bottom: ${rem(64)};

    > *:not(:last-child) {
      margin-bottom: ${rem(48)};
    }
  }

  ${mediaQuery('md')} {
    padding-bottom: ${rem(48)};

    > *:not(:last-child) {
      margin-bottom: ${rem(24)};
    }
  }

  ${mediaQuery('sm')} {
    padding-bottom: ${rem(24)};

    > * {
      margin-bottom: ${rem(24)};
    }
  }
`;

const FooterBottom = styled(Row)`
  padding: ${rem(24)} 0;
  font-size: ${rem(14)};
  color: ${colors.gray300};
  border-top: 1px solid ${colors.gray800};
`;

const FooterHeader = styled(Heading)`
  margin-bottom: ${rem(24)};
`;

const FooterContactLinks = styled(IconLink)`
  &:not(:last-child) {
    margin-bottom: ${rem(32)};

    ${mediaQuery('md')} {
      margin-bottom: ${rem(24)};
    }
  }
`;

const FooterLinks = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    list-style: none;
    display: flex;
    max-width: 100%;

    :not(:last-child) {
      margin-bottom: ${rem(16)};
    }

    a,
    span {
      color: ${colors.gray400};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
