import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { colors } from '~/theme';

const StyledLink = styled(GatsbyLink)`
  color: ${colors.gray800};
  display: inline-block;
  font-size: ${rem(14)};
  line-height: ${16 / 14};
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.25s ease-in-out;
  cursor: pointer;

  &::after {
    background: ${colors.blue500};
    bottom: 0;
    content: '';
    display: block;
    height: ${rem(1)};
    left: 50%;
    position: absolute;
    transition: width 0.25s ease, left 0.25s ease;
    width: 0;
  }

  &.active {
    color: ${colors.blue500};
    pointer-events: none;
  }

  &:hover,
  &:active {
    color: ${colors.blue500};

    &::after {
      left: 0;
      width: 100%;
    }
  }

  ${({ isLight }) =>
    isLight &&
    css`
      color: ${colors.gray100};

      &:hover,
      &:active {
        color: ${colors.white};
      }

      &::after {
        background: ${colors.white};
      }
    `}
`;

export const Link = ({
  href,
  title,
  className,
  onClickCallback,
  isLight,
  isAnchor,
}) => {
  const onLinkClick = () => {
    onClickCallback && onClickCallback();
  };

  return (
    <StyledLink
      as={isAnchor && AnchorLink}
      to={href}
      activeClassName="active"
      className={className}
      onClick={onLinkClick}
      isLight={isLight}
    >
      {title}
    </StyledLink>
  );
};
