import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import { rem } from 'polished';
import styled, { ThemeProvider } from 'styled-components';

import { AppContext } from './context';
import { Footer } from './footer';
import { Header } from './header';
import { Transition } from './transition';

import { GlobalStyles } from '~/theme/global.css';

import { ModalRequest } from '~/components/modal/modal-request';
import { isWindowObjectExist } from '~/core/helpers';
import { useFirstRender, useLockBodyScroll } from '~/core/hooks';
import { colors, theme } from '~/theme';

const PageContainer = styled.div`
  background-color: ${({ isAnimating }) =>
    isAnimating ? colors.gray900 : colors.gray100};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: background-color 0.25s ease-in-out;
`;

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: ${rem(72)};
  min-height: 100vh;

  > :first-child {
    width: 100%;
  }
`;

const Layout = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAnimating, setAnimating] = useState(true);
  const [isAnimationDone, setAnimationDone] = useState(false);
  const location = useLocation();
  const pageRef = useRef();
  const isFirstRender = useFirstRender();

  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);

  const isHeaderLight = ['/lt', '/lt/', '/'].includes(location.pathname);

  const onAnimationComplete = () => {
    setAnimationDone(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setAnimating(false), 4000);

    return () => clearTimeout(timeout);
  }, [isFirstRender]);

  useLockBodyScroll(!isAnimationDone, pageRef);

  return (
    <ThemeProvider theme={theme}>
      <SEOContext.Provider value={{ global: seo }}>
        <AppContext.Provider
          value={{
            isAnimating,
            isAnimationDone,
            setRequestModal: setModalOpen,
          }}
        >
          <GlobalStyles />
          <PageContainer
            ref={pageRef}
            isAnimating={isAnimating && isFirstRender}
          >
            <Transition
              isAnimating={isAnimating && !isAnimationDone}
              initialAnimation={isFirstRender}
              onAnimationComplete={onAnimationComplete}
            />

            <Header
              siteTitle={`Title`}
              isLightMode={isHeaderLight}
              isAnimationDone={isAnimationDone}
            />

            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: isFirstRender ? 0.75 : 0.25,
                    delay: isFirstRender ? 5.25 : 0,
                  },
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                key={location.pathname}
                style={{ position: 'relative', height: '100%' }}
                onAnimationStart={() => {
                  setAnimating(true);
                  isWindowObjectExist() &&
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth',
                    });
                }}
                onAnimationComplete={() => setAnimating(false)}
              >
                <PageContent>{children}</PageContent>
                <Footer />
              </motion.div>
            </AnimatePresence>
          </PageContainer>
          <ModalRequest
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        </AppContext.Provider>
      </SEOContext.Provider>
    </ThemeProvider>
  );
};

export default Layout;
