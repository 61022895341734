import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { useAnimation } from 'framer-motion';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { isWindowObjectExist } from '~/core/helpers';
import { AppContext } from '~/layout/context';

export const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export const useLockBodyScroll = (conditional, ref) => {
  useLayoutEffect(() => {
    const targetElement = ref.current;

    if (targetElement) {
      conditional
        ? disableBodyScroll(targetElement)
        : enableBodyScroll(targetElement);
    }

    return () => clearAllBodyScrollLocks();
  }, [conditional]);
};

export const useEvent = (eventName, eventHandler, dependencies = []) => {
  useEffect(() => {
    if (eventName && eventHandler) {
      window.addEventListener(eventName, eventHandler);
      return () => window.removeEventListener(eventName, eventHandler);
    }
  }, [dependencies, eventHandler, eventName]);
};

export const useAnimationOnViewRef = (threshold, delay) => {
  const { isAnimationDone, isAnimating } = useContext(AppContext);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold,
  });

  useEffect(() => {
    if (inView && isAnimationDone && !isAnimating) {
      setTimeout(() => controls.start('visible'), [delay * 1000]);
    } else {
      controls.start('hidden');
    }
  }, [isAnimationDone, isAnimating, controls, inView]);

  return [ref, controls];
};

// TODO: refactor it to a cleaner version
export const useScroll = () => {
  const isBrowser = isWindowObjectExist();

  const [state, setState] = useState({
    lastScrollTop: 0,
    bodyOffset: isBrowser && document.body.getBoundingClientRect(),
    scrollY: isBrowser && document.body.getBoundingClientRect().top,
    scrollX: isBrowser && document.body.getBoundingClientRect().left,
    scrollDirection: '', // down, up
  });

  const handleScrollEvent = useCallback(() => {
    setState(prevState => {
      if (isBrowser) {
        const prevLastScrollTop = prevState.lastScrollTop;
        const bodyOffset = document.body.getBoundingClientRect();

        return {
          setBodyOffset: bodyOffset,
          scrollY: -bodyOffset.top,
          scrollX: bodyOffset.left,
          scrollDirection: prevLastScrollTop > -bodyOffset.top ? 'down' : 'up',
          lastScrollTop: -bodyOffset.top,
        };
      }
    });
  }, [isBrowser]);

  useEffect(() => {
    const scrollListener = e => {
      handleScrollEvent(e);
    };
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [handleScrollEvent]);

  return {
    scrollY: state.scrollY,
    scrollX: state.scrollX,
    scrollDirection: state.scrollDirection,
  };
};

export const useStaggerReveal = (delay = 0) => {
  const textVariants = {
    hidden: { opacity: 0, y: 32 },
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: delay + i * 0.25,
        duration: 0.5,
        ease: [0.24, 0.1, 0.25, 1],
      },
    }),
  };

  const getStaggerAnimationProps = index => ({
    variants: textVariants,
    custom: index,
    initial: 'hidden',
    animate: 'visible',
  });

  return { getStaggerAnimationProps };
};

export const useWindowSize = () => {
  const isSSR = typeof window === 'undefined';
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  const changeWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
};

export const useGetForm = () => {
  const [formStatus, setFormStatus] = useState({
    isPending: false,
    isSubmitted: false,
    isError: false,
    isSuccess: false,
  });

  const { t } = useTranslation();
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onTouched',
  });

  const onFormSubmit = data => {
    setFormStatus({ ...formStatus, isPending: true });

    fetch('https://getform.io/f/b7ff06b2-a713-4910-89dd-352950ba9913', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        setFormStatus({
          ...formStatus,
          isSubmitted: true,
          isSuccess: true,
          isPending: false,
        });
        reset();
      })
      .catch(() => {
        setFormStatus({
          ...formStatus,
          isSubmitted: true,
          isError: true,
          isPending: false,
        });
      });
  };

  const sharedFieldProps = {
    register,
    errors: formState.errors,
  };

  const translations = {
    info: t('contact.info'),
    letter: t('contact.letter'),
    title: t('form.title'),
    description: t('form.description'),
    name: t('form.name'),
    email: t('form.email'),
    phone: t('form.phone'),
    power: t('form.power'),
    address: t('form.address'),
    message: t('form.message'),
    send: t('form.send'),
    pending: t('form.pending'),
    successText: t('form.successText'),
    failureText: t('form.failureText'),
    sendMessage: t('form.sendMessage'),
    messageContact: t('form.messageContact'),
  };

  return {
    register,
    handleSubmit,
    formState,
    formStatus,
    onFormSubmit,
    sharedFieldProps,
    translations,
  };
};
