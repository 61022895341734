import { rem } from 'polished';

export const boxShadow900 = '10px 10px 50px 3px rgba(39, 92, 141, 0.1)';
export const boxShadow800 =
  '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)';
export const boxShadow700 =
  '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)';

export const borderRadius = {
  sm: rem(4),
  md: rem(8),
  lg: rem(12),
  xl: rem(32),
};

export const gradients = {
  blue: 'linear-gradient(232.91deg, #1B62F8 13.03%, #002F8F 83.64%);',
};

export const zIndexes = {
  hero: 5,
  header: 10,
  loader: 20,
  modal: 40,
};
