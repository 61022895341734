import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { ConditionalWrap } from '~/components/animations/conditional-wrap';
import { isObjectEmpty } from '~/core/helpers';
import { colors, mediaQuery } from '~/theme';

const SIZE_VALUES = {
  sm: 12.8,
  md: 16,
  lg: 20,
};

const getParagraphSize = (size, ratio = 1) => rem(SIZE_VALUES[size] / ratio);

const Text = styled.p`
  color: ${({ color }) => color};
  font-family: 'work sans', sans-serif;
  font-size: ${({ size }) => getParagraphSize(size)};
  line-height: 1.5;
  margin-bottom: ${rem(16)};
  max-width: 70ch;

  ${mediaQuery('md')} {
    font-size: ${({ size }) => getParagraphSize(size, size === 'sm' ? 1 : 1.2)};
  }
`;

// size: lg, md, sm
export const Paragraph = ({
  children,
  size = 'md',
  color = colors.gray700,
  ...animationProps
}) => (
  <ConditionalWrap
    condition={!isObjectEmpty(animationProps)}
    {...animationProps}
  >
    <Text size={size} color={color}>
      {children}
    </Text>
  </ConditionalWrap>
);
