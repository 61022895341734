import { getImage } from 'gatsby-plugin-image';

const getMappedImage = (alt, image) => {
  return {
    thumbnail: getImage(image.localFile.thumbnail),
    full: getImage(image.localFile.full),
    placeholder: getImage(image.localFile.placeholder),
    alt,
  };
};

export const getMappedHeading = data => {
  return {
    title: data.title,
    description: data.headingFields.description,
  };
};

export const getMappedCertificates = certificates => {
  return certificates.map(cerf => ({
    title: cerf.title,
    description: cerf.certificatesFields?.description,
    image: getMappedImage(cerf.title, cerf.certificatesFields?.image),
  }));
};

export const getMappedAllProjects = services => {
  return services
    .map(service => service.serviceFields?.projects)
    .flat(1)
    .map(project => ({
      ...project,
      image: getMappedImage(project.title, project.image),
    }));
};

export const getMappedServiceProjects = service => {
  return service.projects.map(project => ({
    ...project,
    image: getMappedImage(project.title, project.image),
  }));
};

export const getMappedOptions = ({ options: { options } }) => {
  return options;
};
