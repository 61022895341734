import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link as GatsbyLink } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Squeeze as Hamburger } from 'hamburger-react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { LanguageSwitcher } from '~/components/language-switcher';
import { Link } from '~/components/link';
import { useEvent, useLockBodyScroll, useScroll } from '~/core/hooks';
import { linksList } from '~/core/links';
import { ReactComponent as LogoSvg } from '~/static/svg/nrgy-logo.svg';
import {
  boxShadow900,
  colors,
  Container,
  gradients,
  mediaQuery,
  zIndexes,
} from '~/theme';

const linksVariants = {
  hidden: {
    left: '100%',
  },
  visible: {
    left: 0,
    transition: {
      staggerChildren: 0.07,
      type: 'spring',
      bounce: 0,
      duration: 0.25,
      when: 'beforeChildren',
    },
  },
  exit: {
    left: '100%',
    transition: {
      when: 'afterChildren',
      x: { stiffness: 100 },
      staggerChildren: 0.05,
      staggerDirection: 1,
    },
  },
};

const linkVariants = {
  hidden: {
    opacity: 0,
    y: 48,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      x: { stiffness: 1000, velocity: 100 },
    },
  },
  exit: {
    opacity: 0,
    y: 48,
    transition: {
      opacity: { duration: 0.15 },
      y: { stiffness: 100 },
    },
  },
};

export const Header = ({ isLightMode, isAnimationDone }) => {
  const { language: currentLanguage, originalPath } = useI18next();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const mobileMenuRef = useRef();
  const { scrollY } = useScroll();

  const getFormattedTitle = url => {
    return url.replace(/\//g, '').replace('-', ' ');
  };

  const onLinkClick = () => {
    setMenuOpen(false);
  };

  const getPageLink = language => {
    const languagePrefix = language === 'en' ? '' : '/lt';

    if (originalPath === '/') {
      return languagePrefix ? languagePrefix : '/';
    }

    const currentLink = links.find(lng => lng.url === originalPath)?.title;
    const link = linksList[language].find(lng => lng.title === currentLink);

    return link ? `${languagePrefix}${link.url}` : `${languagePrefix ?? '/'}`;
  };

  const links = linksList[currentLanguage];
  const mobileLinks = [
    ...links,
    {
      isSwitcher: true,
      name: currentLanguage === 'lt' ? 'EN' : 'LT',
      url: getPageLink(currentLanguage === 'lt' ? 'en' : 'lt'),
    },
  ];

  const isScrolled = scrollY >= 12 || !isLightMode;

  const renderLink = useCallback(
    (link, timeout) => (
      <motion.li key={link.title} variants={linkVariants}>
        {link.isSwitcher ? (
          <Link
            href={link.url ?? '/'}
            title={link.name}
            onClickCallback={onLinkClick}
            timeout={timeout}
            isLight={!isScrolled}
          />
        ) : (
          <Link
            href={`${currentLanguage === 'lt' ? '/lt' : ''}${link.url}`}
            title={link.name ? link.name : getFormattedTitle(link.url)}
            onClickCallback={onLinkClick}
            timeout={timeout}
            isLight={!isScrolled}
          />
        )}
      </motion.li>
    ),
    [isScrolled, currentLanguage, onLinkClick]
  );

  useLockBodyScroll(isMenuOpen, mobileMenuRef);
  useEvent('resize', () => setMenuOpen(false));

  return (
    <HeaderContainer isScrolled={isScrolled} isAnimationDone={isAnimationDone}>
      <Container>
        <Logo
          to={currentLanguage === 'lt' ? '/lt/' : '/'}
          isScrolled={isScrolled}
        >
          <LogoSvg />
        </Logo>

        <Navigation>
          <Hamburger
            toggled={isMenuOpen}
            toggle={setMenuOpen}
            color={isMenuOpen || !isScrolled ? colors.white : colors.blue500}
          />

          <Links>{links.map(link => renderLink(link))}</Links>
          <LanguageSwitcher getPageLink={getPageLink} isLight={!isScrolled} />

          <AnimatePresence exitBeforeEnter>
            {isMenuOpen && (
              <MobileLinks
                ref={mobileMenuRef}
                variants={linksVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                {mobileLinks.map(link => renderLink(link, 250))}
              </MobileLinks>
            )}
          </AnimatePresence>
        </Navigation>
      </Container>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  padding: ${rem(24)} 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndexes.header};
  background-color: transparent;
  transition: padding 0.25s ease-in-out, background-color 0.25s ease-in-out,
    opacity 0.25s ease-in-out;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      padding: ${rem(12)} 0;
      background-color: ${colors.white};
      box-shadow: ${boxShadow900};
    `}

  ${Container} {
    align-items: center;
    display: flex;
  }

  opacity: ${({ isAnimationDone }) => (isAnimationDone ? 1 : 0)};
`;

const Navigation = styled.div`
  display: flex;
  margin: 0 0 0 auto;

  li {
    list-style: none;
    margin: 0 ${rem(32)} 0 0;
  }

  > :first-child {
    display: none;
    z-index: 2;

    ${mediaQuery('lg')} {
      display: block;
    }
  }
`;

const Links = styled.ul`
  align-items: center;
  display: flex;
  margin: 0;

  ${mediaQuery('lg')} {
    display: none;
  }
`;

const MobileLinks = styled(motion.ul)`
  display: none;

  ${mediaQuery('lg')} {
    align-items: flex-start;
    background: ${gradients.blue};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    margin: 0;
    padding: ${rem(64)} ${rem(32)};
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;

    li {
      margin-bottom: ${rem(48)};

      :last-child {
        font-weight: 700;
      }
    }

    a {
      color: ${colors.white};
      font-size: ${rem(32)};

      &:hover {
        &::after {
          display: none;
        }
      }
    }
  }
`;

const Logo = styled(GatsbyLink)`
  color: ${colors.gray900};
  opacity: 1;
  transform: scale(1);
  transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
  display: flex;

  &:hover {
    transform: scale(0.95);
  }

  ${({ isScrolled }) =>
    !isScrolled &&
    css`
      color: ${colors.gray100};

      ${mediaQuery('lg', 'min')} {
        transform: scale(1.2);

        &:hover {
          transform: scale(1.15);
        }
      }
    `}
`;
