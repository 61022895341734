import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Link } from '~/components/link';
import { ReactComponent as ArrowIcon } from '~/static/svg/arrow-down.svg';
import { borderRadius, boxShadow900, colors, mediaQuery } from '~/theme';

const Toggle = styled.span`
  align-items: center;
  display: flex;

  svg {
    margin-left: ${rem(6)};
    position: relative;
    top: 1px;
    transform: scale(1.5);
    transition: transform 0.25s ease-in-out;
  }
`;

const Dropdown = styled.div`
  background-color: ${colors.white};
  border-radius: ${borderRadius.lg};
  box-shadow: ${boxShadow900};
  left: ${rem(-24)};
  opacity: 0;
  padding: ${rem(16)} ${rem(24)};
  position: absolute;
  top: ${rem(48)};
  transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out;

  a {
    text-transform: uppercase;
  }
`;

const Container = styled.div`
  color: ${colors.blue500};
  display: flex;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;

  ${({ isLight }) =>
    isLight &&
    css`
      color: ${colors.gray100};
    `}

  ${mediaQuery('lg')} {
    display: none;
  }

  &:hover {
    ${Dropdown} {
      opacity: 1;
      top: ${rem(32)};
    }

    ${Toggle} {
      svg {
        transform: rotate(-180deg) scale(1.5);
      }
    }
  }
`;

export const LanguageSwitcher = ({ className, getPageLink, isLight }) => {
  const { language: currentLanguage, languages } = useI18next();

  return (
    <Container className={className} isLight={isLight}>
      <Toggle>
        {currentLanguage} <ArrowIcon />
      </Toggle>

      <Dropdown>
        {languages.map(
          lng =>
            lng !== currentLanguage && (
              <div key={lng}>
                <Link href={getPageLink(lng)} title={lng} />
              </div>
            )
        )}
      </Dropdown>
    </Container>
  );
};
