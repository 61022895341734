import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import { Modal } from './modal';

import { Button } from '~/components/button';
import { Input } from '~/components/form';
import { Heading } from '~/components/heading';
import { Paragraph } from '~/components/paragraph';
import { REGEX } from '~/core/constants';
import { useGetForm } from '~/core/hooks';
import { colors, mediaQuery } from '~/theme';

const Title = styled.div`
  max-width: ${rem(360)};
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0;
  margin: ${rem(16)} 0 0;
  position: relative;

  > * {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${rem(24)};

      ${mediaQuery('sm')} {
        margin-bottom: ${rem(12)};
      }
    }
  }
`;

const FormTwoColumns = styled.div`
  display: flex;

  > * {
    flex: 1;

    &:first-child {
      margin: 0 ${rem(16)} 0 0;
    }
  }

  ${mediaQuery('sm')} {
    flex-direction: column;
    gap: ${rem(12)};

    > * {
      &:first-child {
        margin: 0 0 ${rem(12)};
      }
    }
  }
`;

const FormButton = styled(Button)`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const FormNotification = styled.span`
  position: absolute;
  bottom: ${rem(-6)};
  font-weight: 600;
  color: ${({ isSuccess }) => (isSuccess ? colors.green : colors.red)};

  ${mediaQuery('sm')} {
    bottom: ${rem(-12)};
  }
`;

export const ModalRequest = ({ isOpen, onClose }) => {
  const {
    handleSubmit,
    onFormSubmit,
    formStatus,
    sharedFieldProps,
    translations,
  } = useGetForm();

  return (
    isOpen && (
      <Modal isOpen={isOpen} closeModal={onClose}>
        <Title>
          <Heading size="h4" tag="h3">
            {translations.title}
          </Heading>
          <Paragraph>{translations.description}</Paragraph>
        </Title>

        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <FormTwoColumns>
            <Input
              name="firstName"
              iconName="person"
              placeholder={translations.name}
              {...sharedFieldProps}
            />
            <Input
              iconName="envelope"
              name="email"
              type="email"
              placeholder={translations.email}
              pattern={REGEX.email}
              {...sharedFieldProps}
            />
          </FormTwoColumns>

          <FormTwoColumns>
            <Input
              iconName="phone"
              name="phone"
              type="tel"
              placeholder={translations.phone}
              {...sharedFieldProps}
            />
            <Input
              iconName="location"
              name="address"
              placeholder={translations.address}
              {...sharedFieldProps}
            />
          </FormTwoColumns>

          <Input
            iconName="bolt"
            name="power"
            type="number"
            placeholder={translations.power}
            {...sharedFieldProps}
          />

          <Input
            iconName="chat"
            name="message"
            type="textarea"
            placeholder={translations.message}
            {...sharedFieldProps}
          />

          <FormButton
            size="xl"
            variation="fill"
            type="submit"
            isDisabled={formStatus.isPending}
          >
            {formStatus.isPending ? translations.pending : translations.send}
          </FormButton>

          {formStatus.isSubmitted && (
            <FormNotification isSuccess={formStatus.isSuccess}>
              {formStatus.isSuccess
                ? translations.successText
                : translations.failureText}
            </FormNotification>
          )}
        </Form>
      </Modal>
    )
  );
};
