import React from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Icon } from '~/components/icon';
import { borderRadius, colors } from '~/theme';

const Wrapper = styled.a`
  height: ${rem(40)};
  width: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${colors.gray200};
  border-radius: ${borderRadius.md};

  svg {
    transition: transform 0.25s ease-in-out;
  }

  &:hover {
    svg {
      transform: scale(1.5) rotate(15deg);
    }
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      background-color: transparent;
      height: ${rem(28)};
      width: ${rem(28)};
    `};
`;

export const IconBox = ({ href, className, iconName, isSmall }) => (
  <Wrapper
    href={href}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
    isSmall={isSmall}
  >
    <Icon
      size={isSmall ? (iconName === 'facebook' ? 14 : 16) : 20}
      name={iconName}
    />
  </Wrapper>
);
