import React, { useRef } from 'react';
import ReactModal from 'react-modal';
import { rem } from 'polished';
import styled from 'styled-components';

import { ModalStyles } from './modal.style.css';

import { Icon } from '~/components/icon';
import { useLockBodyScroll } from '~/core/hooks';
import { colors, mediaQuery } from '~/theme';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${rem(500)};

  ${mediaQuery('sm')} {
    justify-content: center;
  }
`;

const ModalCloseButton = styled.button`
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  height: ${rem(40)};
  right: 24px;
  position: absolute;
  top: 24px;
  width: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s ease-in-out;
  background-color: ${colors.gray200};
  padding: 0;

  ${mediaQuery('sm')} {
    position: fixed;
    right: 12px;
    top: 12px;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

export const Modal = ({ children, isOpen, closeModal }) => {
  const modalRef = useRef();

  useLockBodyScroll(isOpen, modalRef);

  return (
    <>
      <ModalStyles />
      <ReactModal
        ref={modalRef}
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal__overlay"
      >
        <ModalCloseButton onClick={closeModal}>
          <Icon name="close" size={14} />
        </ModalCloseButton>
        <ModalContent>{children}</ModalContent>
      </ReactModal>
    </>
  );
};
