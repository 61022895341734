import React from 'react';
import { motion } from 'framer-motion';

const defaultWrapper = (children, props) => (
  <motion.div {...props}>{children}</motion.div>
);

export const ConditionalWrap = ({
  condition,
  wrap = defaultWrapper,
  children,
  ...restProps
}) => (condition ? wrap(children, restProps) : children);
